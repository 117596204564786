<!--
  PACKAGE_NAME : src\pages\esp\hr\user\modal-page-join-user.vue
  FILE_NAME : modal-page-join-user
  AUTHOR : ycnam
  DATE : 2024-07-10
  DESCRIPTION : 사용자 등록 및 수정 모달
-->
<template>
  <div style="overflow-y:auto">
    <DxPopup
      :visible="isOpen"
      :show-title="true"
      ref="userPopup"
      title="사용자 등록"
      min-width="1000"
      width="1000"
      min-height="700"
      height="700"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="true"
      @hiding="closeModal"
    >
      <template>
        <div class="container contents-wrap">
          <div class="page-bin mar_to20 mar_b10"></div>
          <div class="flex flex-col justify-between" style="height=490px;">
            <div>
              <DxValidationGroup ref="validationGroup">
                <table class="table_form line-bin" ref="scrollContainer" style="max-height=450px !important">
                  <colgroup>
                    <col style="width:25%" />
                    <col style="width:25%" />
                    <col style="width:25%" />
                    <col style="width:25%" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>사이트 / 센터 / 팀</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="getSiteList"
                        placeholder="선택"
                        display-expr="siteNm"
                        value-expr="id"
                        v-model="formData.siteId"
                        :width="200"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사이트' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="getTenantList"
                        placeholder="선택"
                        display-expr="tenantNm"
                        value-expr="id"
                        v-model="formData.tenantId"
                        :width="200"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '센터' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="agtTeamList"
                        placeholder="선택"
                        display-expr="teamNm"
                        value-expr="teamId"
                        :width="200"
                        v-model="formData.teamId"
                        @value-changed="onTeamChanged"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '팀' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>사번</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td class="clearfix">
                      <DxTextBox
                        width="200"
                        v-model="formData.userNo"
                        styling-mode="outlined"
                        :disabled="currentSaveType === 'UPDATE'"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사번' })" />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>이름</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td>
                      <DxTextBox width="200" v-model="formData.userNm" styling-mode="outlined">
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '이름' })" />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>내선번호</span>
                      </label>
                    </th>
                    <td>
                      <input class="table_input" type="text" v-model="formData.dn">
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>사용여부</span>
                      </label>
                    </th>
                    <td>
                      <DxSwitch
                        @value-changed="onChangedViewFl($event)"
                        :value="getViewFl"
                        switched-off-text="OFF"
                        switched-on-text="ON"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="4"><hr/></td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>CTI 사용</span>
                      </label>
                    </th>
                    <td>
                      <DxSwitch
                        @value-changed="onChangedCtiFl($event)"
                        :value="getCtiFl"
                        switched-off-text="OFF"
                        switched-on-text="ON"
                      />
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>CTI ID</span>
                      </label>
                    </th>
                    <td>
                      <input class="table_input" type="text" v-model="formData.ctiId">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </DxValidationGroup>
              <div class="page-bin mar_to20 mar_b10"></div>
            </div>
          </div>
          <div class="page-bin mar_to20 mar_b10"></div>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
                        elementAttr: {
                            class: 'default filled txt_S medium',
                        },
                        text: '저장',
                        width: '120',
                        height: '40',
                        useSubmitBehavior: true,
                        onClick: (e) => {
                            onSaveModal(e);
                        }
                    }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
                        elementAttr: {
                            class: 'white filled txt_S medium',
                        },
                        text: '취소',
                        width: '120',
                        height: '40',
                        onClick: () => {
                            closeModal();
                        }
                    }"
      />
    </DxPopup>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSwitch from "devextreme-vue/switch";
import { DxValidationGroup } from 'devextreme-vue/validation-group';

export default {
  components: {  DxPopup, DxToolbarItem, DxCheckBox, DxSelectBox, DxDateBox, DxRequiredRule, DxValidator, DxSwitch, DxTextBox, DxValidationGroup },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    userData : {
      default: () => ({
        rowData: []
      }),
    },
    currentSaveType: {
      type: String,
      default: 'INSERT'
    }
  },
  data() {
    return {
      stylingMode: 'outlined',
      formData: {
        id: null,
        userNo: null,
        userNm: null,
        site: null,
        siteId: null,
        siteNm: null,
        tenant: null,
        tenantNm: null,
        tenantId: null,
        team: null,
        teamId: null,
        teamNm: null,
        ctiFl: 'Y',
        ctiId: null,
        wfmFl: false,
        iptFl: false,
        loginFl: null,
        recFl: 0,
        workDt: null,
        viewFl: 'Y',
        loginId: null,
      },
      showCti: null,
      showWfm: false,
      showIpt: false,
      showCtiDetail: false,
      showWfmDetail: false,
      showIptDetail: false,
      propsData: {
        userNo: '',
        userNm: '',
        ctiFl: 'Y',
        viewFl: 'Y',
      },
      agtTeamList: [],
    };
  },
  watch: {
    async isOpen() {
      if (this.isOpen) {
        await this.initializeData();
      }
    }
  },
  computed: {
    getViewFl() {
      return true; // this.formData.viewFl === 'Y' ? true : false;
    },
    getCtiFl() {
      return true;// this.formData.ctiFl === 'Y' ? true : false;
    },
    /** @description: U_CODE svr_type 가져오기 */
    getSiteList(){
      const result = this.$store.getters.getSiteList;
      return result;
    },
    getTenantList(){
      const result = this.$store.getters.getTenantList;
      return result;
    },
  },
  methods: {
    onChangedViewFl(e) {
      if (e.value) this.formData.viewFl = 'Y'
      else this.formData.viewFl = 'N'
    },
    onChangedCtiFl(e) {
      if (e.value) {
        this.formData.ctiFl = 'Y';
      } else {
        this.formData.ctiFl = 'N';
        this.formData.ctiId = null;
        this.formData.loginId = null;
      }
    },
    async onSaveModal() {
      const validationGroup = this.$refs.validationGroup.instance;
      const validationResult = validationGroup.validate();
      if (!validationResult.isValid) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
        return;
      }
      let selectedSite = this.$store.getters.getSiteList.filter((v) => v.id === this.formData.siteId)[0];
      let selectedTenant = this.$store.getters.getTenantList.filter((v) => v.id === this.formData.tenantId)[0];
      this.formData.site = selectedSite.id;
      this.formData.siteNm = selectedSite.siteNm;
      this.formData.tenant = selectedTenant.id;
      this.formData.tenantNm = selectedTenant.tenantNm;
      // 시간 형식 변환
      const d = new Date();
      const date = new Date(d.getTime()).toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      this.formData.joinDt = date + ' ' + time;
      this.formData.loginFl = 'Y';
      this.formData.recFl = 'N';
      const payload = {
        actionname: 'USER_SAVE',
        data: {
          from: "core",
          data : [this.formData]
        },
        loading: true,
      };
      const res = await this.CALL_API(payload);
      if (res.status === 200) {
        this.$_Msg('성공');
      } else {
        this.$_Msg('실패');
      }

      this.setInitModal();
    },
    /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
    setInitModal() {
      this.$emit('saveModal');
      this.propsData = {};
    },
    /** @description : 팝업 취소 버튼 액션  */
    closeModal() {
      this.$emit('closeModal');
      this.propsData = {};
    },
    onToggle(type) {
      if (type === 'CTI') {
        this.showCtiDetail = this.showCtiDetail ? false : true;
      } else if (type === 'WFM') {
        this.showWfmDetail = this.showWfmDetail ? false : true;
      } else if (type === 'IPT') {
        this.showIptDetail = this.showIptDetail ? false : true;
      }
    },
    onValueChanged(e) {
      if (e.value === true) {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = true;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = true;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = true;
        }
      } else {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = false;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = false;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = false;
        }
      }
    },
    onTeamChanged(e) {
      const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(e.value));
      if (selectedTeam) {
        this.formData.teamNm = selectedTeam.teamNm;
        this.formData.teamId = selectedTeam.teamId;
        this.formData.team = selectedTeam.team;
      } else {
        this.formData.teamNm = null;
        this.formData.teamId = null;
        this.formData.team = null;
      }
    },
    async getAgtTeamList(){
      const yesFlag = this.$_enums.common.stringViewFlag.YES.value;
      const payload = {
        actionname: 'CC_AGTTEAM_LIST',
        data: {
          sort: '+agtteamNm',
          viewFl: yesFlag,
        },
      }
      const res = await this.CALL_CC_API(payload);
      this.agtTeamList = res.data.data
        .filter(item => item.agtteam && item.agtteamid && item.agtteamNm)
        .map(item => ({
          team: item.agtteam,
          teamId: item.agtteamid,
          teamNm: item.agtteamNm
        }));
    },
    async initializeData() {
      this.formData = this.userData?.rowData ? { ...this.userData.rowData[0] } : {};
      if (this.formData.teamId) {
        const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(this.formData.teamId));
        if (selectedTeam) {
          this.formData.teamNm = selectedTeam.teamNm;
          this.formData.teamId = selectedTeam.teamId;
          this.formData.team = selectedTeam.team;
        }
      }

      this.$nextTick(() => {
        const validationGroup = this.$refs.validationGroup;
        if (validationGroup && validationGroup.instance) {
          //validationGroup.instance.reset();
        }
      });
    },
  },
  async created() {
    await this.getAgtTeamList();
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dx-popup-content {
    overflow-y: auto !important;
  }

  .table_form {
    height: 100%;
    border: none;
    max-height: 400px;
    overflow-y: auto !important;
  }

  .clearfix {
    margin: 0 auto; // 가운데정렬
    //width: 88%;
  }

  .table_input {
    border : 1px solid #dcdcdc !important;
    border-radius : 4px;
    width: 200px;
    height: 30px;
  }

  .additional-info {
    background-color: white;
    border-top: 1.5px solid #c2c0c0;
    border-bottom: 1.5px solid #c2c0c0;
  }

  .additional-info th {
    background-color: white;
  }

}
</style>
<!--
  PACKAGE_NAME : src\pages\esp\hr\user\modal-page-join-user.vue
  FILE_NAME : modal-page-join-user
  AUTHOR : ycnam
  DATE : 2024-07-10
  DESCRIPTION : 사용자 등록 및 수정 모달
-->
<template>
  <div style="overflow-y:auto">
    <DxPopup
      :visible="isOpen"
      :show-title="true"
      ref="userPopup"
      title="사용자 등록"
      min-width="1000"
      width="1000"
      min-height="700"
      height="700"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="true"
      @hiding="closeModal"
    >
      <template>
        <div class="container contents-wrap">
          <div class="page-bin mar_to20 mar_b10"></div>
          <div class="flex flex-col justify-between" style="height=490px;">
            <div>
              <DxValidationGroup ref="validationGroup">
                <table class="table_form line-bin" ref="scrollContainer" style="max-height=450px !important">
                  <colgroup>
                    <col style="width:25%" />
                    <col style="width:25%" />
                    <col style="width:25%" />
                    <col style="width:25%" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>사이트 / 센터 / 팀</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="getSiteList"
                        placeholder="선택"
                        display-expr="siteNm"
                        value-expr="id"
                        v-model="formData.siteId"
                        :width="200"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사이트' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="getTenantList"
                        placeholder="선택"
                        display-expr="tenantNm"
                        value-expr="id"
                        v-model="formData.tenantId"
                        :width="200"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '센터' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                    <td class="clearfix">
                      <DxSelectBox
                        :items="agtTeamList"
                        placeholder="선택"
                        display-expr="teamNm"
                        value-expr="teamId"
                        :width="200"
                        v-model="formData.teamId"
                        @value-changed="onTeamChanged"
                        :styling-mode="stylingMode"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '팀' })" />
                        </DxValidator>
                      </DxSelectBox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>사번</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td class="clearfix">
                      <DxTextBox
                        width="200"
                        v-model="formData.userNo"
                        styling-mode="outlined"
                        :disabled="currentSaveType === 'UPDATE'"
                      >
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사번' })" />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>이름</span>
                        <span class="icon_require">필수항목</span>
                      </label>
                    </th>
                    <td>
                      <DxTextBox width="200" v-model="formData.userNm" styling-mode="outlined">
                        <DxValidator>
                          <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '이름' })" />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>내선번호</span>
                      </label>
                    </th>
                    <td>
                      <input class="table_input" type="text" v-model="formData.dn">
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>사용여부</span>
                      </label>
                    </th>
                    <td>
                      <DxSwitch
                        @value-changed="onChangedViewFl($event)"
                        :value="getViewFl"
                        switched-off-text="OFF"
                        switched-on-text="ON"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="4"><hr/></td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <label for="label5">
                        <span>CTI 사용</span>
                      </label>
                    </th>
                    <td>
                      <DxSwitch
                        @value-changed="onChangedCtiFl($event)"
                        :value="getCtiFl"
                        switched-off-text="OFF"
                        switched-on-text="ON"
                      />
                    </td>
                    <th scope="row">
                      <label for="label5">
                        <span>CTI ID</span>
                      </label>
                    </th>
                    <td>
                      <input class="table_input" type="text" v-model="formData.ctiId">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </DxValidationGroup>
              <div class="page-bin mar_to20 mar_b10"></div>
            </div>
          </div>
          <div class="page-bin mar_to20 mar_b10"></div>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
                        elementAttr: {
                            class: 'default filled txt_S medium',
                        },
                        text: '저장',
                        width: '120',
                        height: '40',
                        useSubmitBehavior: true,
                        onClick: (e) => {
                            onSaveModal(e);
                        }
                    }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
                        elementAttr: {
                            class: 'white filled txt_S medium',
                        },
                        text: '취소',
                        width: '120',
                        height: '40',
                        onClick: () => {
                            closeModal();
                        }
                    }"
      />
    </DxPopup>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSwitch from "devextreme-vue/switch";
import { DxValidationGroup } from 'devextreme-vue/validation-group';

export default {
  components: {  DxPopup, DxToolbarItem, DxCheckBox, DxSelectBox, DxDateBox, DxRequiredRule, DxValidator, DxSwitch, DxTextBox, DxValidationGroup },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    userData : {
      default: () => ({
        rowData: []
      }),
    },
    currentSaveType: {
      type: String,
      default: 'INSERT'
    }
  },
  data() {
    return {
      stylingMode: 'outlined',
      formData: {
        id: null,
        userNo: null,
        userNm: null,
        site: null,
        siteId: null,
        siteNm: null,
        tenant: null,
        tenantNm: null,
        tenantId: null,
        team: null,
        teamId: null,
        teamNm: null,
        ctiFl: 'Y',
        ctiId: null,
        wfmFl: false,
        iptFl: false,
        loginFl: null,
        recFl: 0,
        workDt: null,
        viewFl: 'Y',
        loginId: null,
      },
      showCti: null,
      showWfm: false,
      showIpt: false,
      showCtiDetail: false,
      showWfmDetail: false,
      showIptDetail: false,
      propsData: {
        userNo: '',
        userNm: '',
        ctiFl: 'Y',
        viewFl: 'Y',
      },
      agtTeamList: [],
    };
  },
  watch: {
    async isOpen() {
      if (this.isOpen) {
        await this.initializeData();
      }
    }
  },
  computed: {
    getViewFl() {
      return true; // this.formData.viewFl === 'Y' ? true : false;
    },
    getCtiFl() {
      return true;// this.formData.ctiFl === 'Y' ? true : false;
    },
    /** @description: U_CODE svr_type 가져오기 */
    getSiteList(){
      const result = this.$store.getters.getSiteList;
      return result;
    },
    getTenantList(){
      const result = this.$store.getters.getTenantList;
      return result;
    },
  },
  methods: {
    onChangedViewFl(e) {
      if (e.value) this.formData.viewFl = 'Y'
      else this.formData.viewFl = 'N'
    },
    onChangedCtiFl(e) {
      if (e.value) {
        this.formData.ctiFl = 'Y';
      } else {
        this.formData.ctiFl = 'N';
        this.formData.ctiId = null;
        this.formData.loginId = null;
      }
    },
    async onSaveModal() {
      const validationGroup = this.$refs.validationGroup.instance;
      const validationResult = validationGroup.validate();
      if (!validationResult.isValid) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
        return;
      }
      let selectedSite = this.$store.getters.getSiteList.filter((v) => v.id === this.formData.siteId)[0];
      let selectedTenant = this.$store.getters.getTenantList.filter((v) => v.id === this.formData.tenantId)[0];
      this.formData.site = selectedSite.id;
      this.formData.siteNm = selectedSite.siteNm;
      this.formData.tenant = selectedTenant.id;
      this.formData.tenantNm = selectedTenant.tenantNm;
      // 시간 형식 변환
      const d = new Date();
      const date = new Date(d.getTime()).toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      this.formData.joinDt = date + ' ' + time;
      this.formData.loginFl = 'Y';
      this.formData.recFl = 'N';
      const payload = {
        actionname: 'USER_SAVE',
        data: {
          from: "core",
          data : [this.formData]
        },
        loading: true,
      };
      const res = await this.CALL_API(payload);
      if (res.status === 200) {
        this.$_Msg('성공');
      } else {
        this.$_Msg('실패');
      }

      this.setInitModal();
    },
    /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
    setInitModal() {
      this.$emit('saveModal');
      this.propsData = {};
    },
    /** @description : 팝업 취소 버튼 액션  */
    closeModal() {
      this.$emit('closeModal');
      this.propsData = {};
    },
    onToggle(type) {
      if (type === 'CTI') {
        this.showCtiDetail = this.showCtiDetail ? false : true;
      } else if (type === 'WFM') {
        this.showWfmDetail = this.showWfmDetail ? false : true;
      } else if (type === 'IPT') {
        this.showIptDetail = this.showIptDetail ? false : true;
      }
    },
    onValueChanged(e) {
      if (e.value === true) {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = true;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = true;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = true;
        }
      } else {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = false;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = false;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = false;
        }
      }
    },
    onTeamChanged(e) {
      const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(e.value));
      if (selectedTeam) {
        this.formData.teamNm = selectedTeam.teamNm;
        this.formData.teamId = selectedTeam.teamId;
        this.formData.team = selectedTeam.team;
      } else {
        this.formData.teamNm = null;
        this.formData.teamId = null;
        this.formData.team = null;
      }
    },
    async getAgtTeamList(){
      const yesFlag = this.$_enums.common.stringViewFlag.YES.value;
      const payload = {
        actionname: 'CC_AGTTEAM_LIST',
        data: {
          sort: '+agtteamNm',
          viewFl: yesFlag,
        },
      }
      const res = await this.CALL_CC_API(payload);
      this.agtTeamList = res.data.data
        .filter(item => item.agtteam && item.agtteamid && item.agtteamNm)
        .map(item => ({
          team: item.agtteam,
          teamId: item.agtteamid,
          teamNm: item.agtteamNm
        }));
    },
    async initializeData() {
      this.formData = this.userData?.rowData ? { ...this.userData.rowData[0] } : {};
      if (this.formData.teamId) {
        const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(this.formData.teamId));
        if (selectedTeam) {
          this.formData.teamNm = selectedTeam.teamNm;
          this.formData.teamId = selectedTeam.teamId;
          this.formData.team = selectedTeam.team;
        }
      }

      this.$nextTick(() => {
        const validationGroup = this.$refs.validationGroup;
        if (validationGroup && validationGroup.instance) {
          //validationGroup.instance.reset();
        }
      });
    },
  },
  async created() {
    await this.getAgtTeamList();
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dx-popup-content {
    overflow-y: auto !important;
  }

  .table_form {
    height: 100%;
    border: none;
    max-height: 400px;
    overflow-y: auto !important;
  }

  .clearfix {
    margin: 0 auto; // 가운데정렬
    //width: 88%;
  }

  .table_input {
    border : 1px solid #dcdcdc !important;
    border-radius : 4px;
    width: 200px;
    height: 30px;
  }

  .additional-info {
    background-color: white;
    border-top: 1.5px solid #c2c0c0;
    border-bottom: 1.5px solid #c2c0c0;
  }

  .additional-info th {
    background-color: white;
  }

}
</style>
